.graphContainer {
    width: 45vw;
   
    background: white;
    padding: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 10px 0px -5px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 10px 0px -5px rgba(0,0,0,0.3);
    box-shadow: 0px 10px 0px -5px rgba(0,0,0,0.3);
}

@media only screen and (max-width: 600px) {
    .graphContainer {
        width: 78vw;
    }
}