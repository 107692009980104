body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;font-family: 'Libre Baskerville', serif;
  font-family: 'Nova Slim', cursive;
  background-color:#eee;

}

.reset-expansion-style
{
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.topbar
{
width: 100%;
color: #fff;
background-color: #EE6E73;

}
.bar{
  padding: 25px;
}
.topbar i
{font-size: 30px;

}
.clear
{
  clear: both;
  float: none;
}
.lf
{
  float: left;
  
}
.rf
{
  float: right;
  
}
.rs{
font-size: 15px; font-weight: 500;
}
.iconmenu
{
  text-align: left;
  width: 30%;
}

.txtmenu
{
  text-align: left;
    
    margin-left: 21%;
}
/*.logo{
  margin-left: 3%;    margin-top: -3px;
  font-size: 25px; font-weight: 500; text-transform: uppercase;
}*/
.log{
  text-align: center;
}

.logo{
  margin:auto; text-align: center; padding-top: 15%;  padding-bottom: 15%;  border-bottom: 0px solid #ccc;
  font-size: 30px; font-weight: 500; text-transform: uppercase; color: #fff;  
}
.logo i {

  color: #fff;
}
.cartab
{
  margin-left: 3%;    margin-top: -3px;
  font-size: 18px; font-weight: 500; text-transform: uppercase;
  color: #EE6E73;
  background-color: #fff;
  border-radius: 6px;
  padding:5px 2% ;
}
.cartab i
{font-size: 20px;

}
.leftdiv
{
width: 23%;
border-right: 1px solid #1B2B34; position: fixed; left: 0; bottom: 0; top:0;
background-color: #fff;
}
.leftdiv1{
  background-color: #fff; 
  color: #000; 
position: fixed; left: 0; top: 0; right: 0; height: 60px; z-index: 30303;
  margin: auto;
  
 padding: 5px 3px;
 border-bottom: 1px solid #ccc;
}
.divsearch
{
  width: 85%;

}
.menubar
{
  margin:0 1%;
margin-top: 5px;
}
.bold
{
  font-weight: 600;
}

.rightdiv
{
  width: 77%;

  
  }
  .rightdiv1
  {

    margin: 0 auto;
    text-align: center;
    margin-top: 60px;
    
    }
.search
{
  
  padding: 10px 0px;
}
.searchi
{

color: rgb(212, 212, 212);
float: left;

}
.searchbtn
{background-color: rgba(90, 90, 94, 0.726);
  padding: 10px 14px;
  border-radius: 0px 8px 8px 0px;
  color: #fff;
  border-bottom: 1px solid #333;
}
.searchbtn:hover
{
color:#fff; text-decoration: none;
}
.col-sm-12
{}
.searchinp
{
  color: #1B2B34;
    display: block;
    font-size: 14px;
    font-weight: 300;
    width: 83%;
    height: 42px;
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 45px 0 10px;
    border: 0;background-color: transparent;   
     transition: box-shadow .3s, border .3s, -webkit-box-shadow .3s;    overflow: visible;
     outline: none;
     box-shadow: none;
      border: 1px solid #1B2B34;    float: left;
}
::placeholder
{
  color: #999;
}
.menuitem
{
  text-align: center;
  color:#fff;
 
  border-top: 1px solid #eee; 
  padding: 10px 0; font-size: 18px;
}
.cl{
  margin-top: 10px;
}
.menuitem:hover
{
  background-color: #3E4095;
  color: #fff; cursor: pointer;
}
.divact
{
  margin-top: 12px;
}
.actives
{
  background-color: #EE6E73;
  color: #fff;
}

.rightdiv .row {
  margin-right: -0px; 
  margin-left: -16px;
}
.nav-tabs li {
  width: 33.33%;
}
.mmmmm
{
  margin-top: 60px; 
}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover
{
  color: #fff;
  font-size: 20px;    border: 1px solid #1B2B34; background-color: #1B2B34;    border-bottom-color: transparent;
}
#tabs .nav-tabs>li>a {
  font-size: 14px;    padding: 6px 15px;
}
#tabs .nav-tabs>li>a:hover
{
  background-color: #1B2B34; border:1px solid #1B2B34; color: #fff;
}
.nav-tabs {
  border-bottom: 1px solid #1B2B34;
}
.bkgoudmenu
{
  display: block;
}
.bkgoudmenu .nav>li>a {
text-align: center; border-top: 1px solid #1B2B34;  color: #fff;padding: 0;
}
.bkgoudmenu .nav>li>a>a {
    padding: 0px;
} .nav>li>a {
  color: #1B2B34;
}
.bkgoudmenu .nav>li>a>a>div {
  padding: 3px 15px;
  margin-top: 6px;
}

.bkgoudmenu .nav>li :hover {
  background-color: #3E4095;
  color: #fff; cursor: pointer;
}.bkgoudmenu .nav>li>a>a {
  
  color: #fff; 
}
.modal
{
  font-size: 50px; color :#EE6E73;
}

.maindetailscars
{
background-color: #fff;
border:1px solid #ccc;
width: 100%; margin-bottom: 60px;
}
.symdetail 
{
width: 15%;text-align: center;
padding: 10px 3px;font-weight: bold; font-size: 20px;
}

.detaildoc
{
  
 font-family: 'Libre Baskerville', serif;
  padding: 3px;
  font-weight: 100;
  font-size: 13px;
  color: #666;
  text-align: left;
  padding-left: 21%;

}

.detaildoc1
{
  width: 50%;
 font-family: 'Libre Baskerville', serif;
  padding: 3px;
  font-weight: 100;
  font-size: 13px;
  color: #666;
  text-align: left;
 

}
.detaildoc2
{
  width: 50%;
  font-family: 'Libre Baskerville', serif;
   padding: 3px;
   font-weight: 100;
   font-size: 13px;
   color: #666;
   text-align: left;
  
}


.docdesc
{
margin-left: 30px;
margin-top: 4px;
color: #888;
}

.detaildocno
{
width: 40%; font-family: 'Libre Baskerville', serif;
text-align: left;
padding: 3px;
font-weight: 500; font-size: 13px;
color: #222;
}
.detail 
{
width: 40%;
text-align: center;
padding: 10px 3px;
font-weight: bold; font-size: 14px; 


}


.detail1 
{
width: 90%;
text-align: center;
padding: 10px 3px;
font-weight: bold; font-size: 20px; margin: auto;
}
.detailstars
{
width: 40%;
text-align: left;
padding: 10px 3px;
font-weight: bold; font-size: 20px;
color: rgb(255, 191, 0);
}
.detaildivs
{
  border-bottom: 1px solid #ccc;
}
.descdetaildoc
{
  padding: 8px 20px;
  border-bottom: 1px solid #eee;

}
.descdetaildocbtom
{
  border-bottom:1px solid #ccc; background-color: #efefef;
}
.divactbtn
{
  background-color:rgb(255, 191, 0);
  padding: 10px 20px;
  margin-left: 5%;
  margin-top: 5px ; color: #000; border-radius: 2px; font-weight: 600;
}
.logos1
{
  width: 120px;

}