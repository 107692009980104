.container {
  
    width: 100%;
    background: var(--theme-primary);
    color: #1CC88A;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

header {
    display: flex;
    align-items: center;
}

header > img {
    margin-right: 5px;
}

.buttonContainer {
    display: flex;
    justify-content: space-around;
    width: fit-content;
    margin-bottom: 20px;
    margin-top: -60px;
}

.buttonContainer button {
    background: transparent;
    color: #2A405C;
    font-size: 16px;
    font-family: "PT Sans";
    border: 2px solid #2A405C;
    border-radius: 5px;
    padding: 2px 10px;
    transition: all 200ms ease-in-out;
    cursor: pointer;
    outline-color: #2A405C;
}

.buttonContainer button:hover {
    background: rgb(255, 255, 255, 0.1);
    padding: 2px 20px;
}

button[value=annual] { 
   border-right: none;
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
}

button[value=lastquarter] { 
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}